import React, { useContext } from 'react'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import { UserContext } from '/src/contexts/UserContext'
import './styles.scss'

const AppointmentPickerBlock = loadable(() =>
    import('/src/components/PageBuilder/layouts/standard/AppointmentPickerBlock')
)
const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const FooterEmail = loadable(() => import('/src/components/Email/FooterEmail'))
const LayoutInner = loadable(() => import('/src/components/Layout/LayoutInner'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const Seo = loadable(() => import('/src/components/Structure/Seo'))
const VideoPlayer = loadable(() => import('/src/components/Media/VideoPlayer'))

const WelcomeVideoEmailTemplate = () => {
    const [options] = useContext(OptionsContext)
    const [user] = useContext(UserContext)

    const post = {
        seo: {
            title: `Welcome Video - ${options.siteData.title}`,
            metaRobotsNoindex: 'noindex',
            metaRobotsNofollow: 'nofollow'
        }
    }

    return (
        <LayoutInner removeHeader={true} removeFooter={true}>
            <Seo post={post} />

            <main>
                <Section variant={'light'} size={'sm'} className={'c-welcome-video-content'}>
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
                            <VideoPlayer
                                data={'https://protect-line-marketing.wistia.com/medias/iuioujgoay'}
                                variant={'square'}
                                light={false}
                            />

                            <CtaContainer align={'centered'} variant={'compact'}>
                                <ButtonLink
                                    data={{
                                        url: '#form',
                                        title: 'Schedule a Call'
                                    }}
                                    variant={'alt5'}
                                    size={'lg'}
                                    style={{
                                        marginTop: '20px'
                                    }}
                                />
                            </CtaContainer>

                            <p>
                                <strong>Hi{user.name && ` ${user.name}`},</strong>
                            </p>

                            <p>I've tried calling you about your Life Insurance quote.</p>

                            <p>
                                I haven't been able to reach you so I wanted to <strong>send a quick video</strong> to
                                say hello and introduce myself!
                            </p>

                            <p>
                                Hope to speak to you soon,
                                <br />
                                <strong>Alexia</strong>
                                <br />
                                <strong>Protect Line</strong>
                            </p>

                            <CtaContainer align={'centered'} variant={'compact'}>
                                <ButtonLink
                                    data={{ url: '#form', title: 'Get Your Quote' }}
                                    variant={'alt5'}
                                    size={'lg'}
                                />
                            </CtaContainer>

                            <img
                                src="https://mail1.force24.co.uk/TK/TestTemplates/f47ed4e5/b20fde87/images/1653042913-70aeb484.png"
                                width="280"
                                alt="Trustpilot"
                                style={{ display: 'block', margin: '0 auto' }}
                            />
                        </div>
                    </div>
                </Section>

                <AppointmentPickerBlock
                    backgroundColour={'alt'}
                    size={'sm'}
                    titleArea={{ addtitleArea: false }}
                    user={user}
                />

                <FooterEmail />
            </main>
        </LayoutInner>
    )
}

export default WelcomeVideoEmailTemplate
